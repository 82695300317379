/* eslint-disable */
// @ts-ignore
import { defineAsyncComponent } from 'vue'
export const registerComponents = ( app:any ) => {
  app.component("NavBar", defineAsyncComponent(() => import("@/components/NavBar.vue")))
  app.component("PageContent", defineAsyncComponent(() => import("@/components/Content.vue")))
  app.component("Achieve", defineAsyncComponent(() => import("@/components/Achieve.vue")))
  app.component("Utilize", defineAsyncComponent(() => import("@/components/Utilize.vue")))
  app.component("Contact", defineAsyncComponent(() => import("@/components/Contact.vue")))
  // Mobile
  app.component("NavBarMobile", defineAsyncComponent(() => import("@/components/mobile/NavBarMobile.vue")))
  app.component("PageContentMobile", defineAsyncComponent(() => import("@/components/mobile/ContentMobile.vue")))
  app.component("AchieveMobile", defineAsyncComponent(() => import("@/components/mobile/AchieveMobile.vue")))
  app.component("UtilizeMobile", defineAsyncComponent(() => import("@/components/mobile/UtilizeMobile.vue")))
  app.component("ContactMobile", defineAsyncComponent(() => import("@/components/mobile/ContactMobile.vue")))
}
